var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.cancelConfirmed
    ? _c("div", { staticClass: "cancel-confirmed" }, [
        _c("h1", { staticClass: "cancellation-title" }, [
          _vm._v("Compliance Cancellation"),
        ]),
        _c("div", { staticClass: "cancel-message" }, [
          _c("p", [
            _vm._v(
              "\n      We're sad to hear that you're canceling your Compliance Service for " +
                _vm._s(_vm.companyName) +
                ",\n      " +
                _vm._s(_vm.jurisdiction) +
                ". Your cancellation request has been processed, but we hope you'll\n      consider us again in the near future for all your Compliance needs.\n    "
            ),
          ]),
          _vm.canRefund
            ? _c("p", [
                _vm._v(
                  "\n      You will receive an email when you have been refunded $" +
                    _vm._s(_vm.calculateRate.toFixed(2)) +
                    " to your original payment method.\n    "
                ),
              ])
            : _vm._e(),
          _c("p", [
            _vm._v(
              "\n      To allow us to file your state filings in the future, you will need to reactivate the Compliance Service.\n    "
            ),
          ]),
        ]),
      ])
    : _vm.loaded && _vm.canCancel
    ? _c(
        "div",
        { staticClass: "cancel-service" },
        [
          _c("div", { staticClass: "header" }, [
            _c("p", { staticClass: "cancel-service-title" }, [
              _vm._v("Cancel Service"),
            ]),
            _c("p", { staticClass: "company-name" }, [
              _vm._v(_vm._s(_vm.companyName)),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "service-card" },
            [
              _c(
                "b-row",
                { staticClass: "header-row" },
                [
                  _c("b-col", [
                    _vm._v(
                      "\n        Compliance Service and State Filing\n      "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "body-row" },
                [
                  _c("b-col", [_vm._v("\n        Jurisdiction\n      ")]),
                  _c("b-col", { staticClass: "text-right" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.jurisdiction) + "\n      "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "body-row" },
                [
                  _c("b-col", [_vm._v("\n        Rate\n      ")]),
                  _c("b-col", { staticClass: "text-right" }, [
                    _vm._v(
                      "\n        $" +
                        _vm._s(_vm.calculateRate.toFixed(2)) +
                        "\n      "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  class: [
                    "body-row",
                    { "no-border": _vm.selectedReason === "other" },
                  ],
                },
                [
                  _c("b-col", [
                    _vm._v("\n        Reason for Cancellation\n      "),
                  ]),
                  _c("b-col", { staticClass: "text-right" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedReason,
                            expression: "selectedReason",
                          },
                        ],
                        staticClass: "form-control select-box",
                        attrs: { "aria-label": "Reason for cancellation" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedReason = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "" } }, [
                          _vm._v("Please select a reason"),
                        ]),
                        _vm._l(_vm.reasons, function (reason) {
                          return _c(
                            "option",
                            { key: reason, domProps: { value: reason } },
                            [_vm._v(_vm._s(reason))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
              _vm.selectedReason === "Other"
                ? _c(
                    "b-row",
                    { staticClass: "body-row" },
                    [
                      _c("b-col", [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.otherNote,
                              expression: "otherNote",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "Enter reason",
                            maxlength: "250",
                            "aria-label": "Enter reason for cancellation",
                          },
                          domProps: { value: _vm.otherNote },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.otherNote = $event.target.value
                            },
                          },
                        }),
                        _c("div", { staticClass: "text-right text-muted" }, [
                          _vm._v(
                            _vm._s(_vm.remainingCharacters) + " characters left"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.canRefund
            ? _c(
                "div",
                { staticClass: "refund-card" },
                [
                  _c(
                    "b-row",
                    { staticClass: "header-row" },
                    [
                      _c("b-col", [_vm._v("\n        Refund Details\n      ")]),
                      _c("b-col", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n        $" +
                            _vm._s(_vm.invoice.total.toFixed(2)) +
                            "\n      "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "body-row" },
                    [
                      _c("b-col", [_vm._v("\n        Invoice #\n      ")]),
                      _c("b-col", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.invoice.invoice_number) +
                            "\n      "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "body-row" },
                    [
                      _c("b-col", [_vm._v("\n        Payment Method\n      ")]),
                      _c(
                        "b-col",
                        { staticClass: "text-right" },
                        [
                          _c("b-img", {
                            staticClass: "mr-2",
                            attrs: {
                              src: _vm.cardLogo(
                                _vm.invoice.payment_info.card.brand
                              ),
                            },
                          }),
                          _vm._v(
                            "\n        **" +
                              _vm._s(_vm.invoice.payment_info.card.last4) +
                              "\n      "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "action-buttons" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-left" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "grey-button-outlined",
                          attrs: {
                            variant: "outline-secondary",
                            "aria-label": "Cancel and go back",
                            to: "/dashpanel",
                          },
                        },
                        [_vm._v("\n          Cancel\n        ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "custom-blue-button",
                          attrs: { "aria-label": "Submit cancellation" },
                          on: { click: _vm.openCancellationConfirmation },
                        },
                        [_vm._v("\n          Submit\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("cancellation-confirmation-modal", {
            attrs: {
              "company-name": _vm.companyName,
              jurisdiction: _vm.jurisdiction,
              reason: _vm.selectedReason,
              "refund-amount": _vm.canRefund
                ? _vm.invoice.total.toFixed(2)
                : null,
              card: _vm.canRefund ? _vm.invoice.payment_info.card : null,
            },
            on: { confirm: _vm.confirmCancellation },
            model: {
              value: _vm.showModal,
              callback: function ($$v) {
                _vm.showModal = $$v
              },
              expression: "showModal",
            },
          }),
        ],
        1
      )
    : _vm.loaded
    ? _c("div", { staticClass: "cancel-confirmed" }, [
        _c("span", [
          _vm._v(
            "No filings or active compliance service available for refund / cancel"
          ),
        ]),
      ])
    : _c(
        "div",
        { staticClass: "nav-spinner-container" },
        [_c("ct-centered-spinner")],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }